body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 0.9rem;
}

h1, h2, h3 {
  font-family: sans-serif;
  overflow: hidden;
}

a:visited {
  color: inherit;
}

.no-bottom-margin {
  margin-bottom: 0;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: #f5f5f5;
  }

  /* .MuiPaper-root, .MuiTableCell-body, .MuiTableCell-head {
    background-color: #010101;
    color: #f5f5f5 !important;
  }

  a {
    color: #f5f5f5;
  }

  a:hover {
    color: #999;
  }

  button {
    background-color: #4e4e4e;
    color: #f5f5f5;
  }

  button:hover {
    background-color: #999;
    color: #333;
  } */
}
